<template>
	<form
		:action="actionForm"
		method="post">
		<input
			v-for="element in elements"
			:key="element"
			type="text"
			name="element_uuid[]"
			:value="element"
			hidden />

		<input
			type="text"
			name="back_url"
			:value="backUrlAfterPay"
			hidden />

		<input
			type="text"
			name="type"
			:value="type"
			hidden />

		<input
			type="text"
			name="token"
			:value="tokenAuth"
			hidden />

		<Button
			type="submit"
			:disabled="!hasElements"
			class="w-full justify-content-center font-bold">
			Оплатить &nbsp;

			<span v-if="sum">
				{{ useFormatCurrency(Number(sum)) }}
			</span>
		</Button>
	</form>
</template>

<script setup lang="ts">
	import { useFormatCurrency } from "~/composables/useFormatCurrency";
	import type { IPaymentFormProps } from "./IPaymentFormProps";

	const config = useRuntimeConfig();
	const { origin } = useRequestURL();
	const auth = useAuth();

	const props = defineProps<IPaymentFormProps>();

	const hasElements = computed((): boolean => (Array.isArray(props.elements) ? !!props.elements.length : false));

	const actionForm = computed((): string => `${config.public.API_URL}/pay/`);

	const backUrlAfterPay = computed((): string => `${origin}${props.backUrl}`);

	const tokenAuth = auth.data.value?.user.accessToken;
</script>
